import { CheckIcon } from "@heroicons/react/20/solid";
import * as Dialog from "@radix-ui/react-dialog";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useState } from "react";
import {
  EnumSubscriptionInterval,
  EnumUserSubscriptionType,
} from "../../__generated__/graphql";
import { useToastsContext } from "../../contexts/toasts";
import useStores from "../../hooks/useStores";
import ButtonLarge from "../ButtonLarge";

type PricingCardProps = {
  id: string;
  title: string;
  isAnnual: boolean;
  subscriptionType: string;
  features: Array<{ text: string; included: boolean }>;
  price?: { monthly: number; annually: number };
  isHighlighted?: boolean;
  isFree?: boolean;
  onSelectPricingPlan: (
    subscriptionType: string,
    paymentInterval: string
  ) => void;
  isActive?: boolean | ((isAnnual: boolean) => boolean);
  isDisabled?: boolean;
  isUserSubscribed?: boolean;
  isEnterprise?: boolean;
};

const PricingCard = ({
  title,
  price,
  features,
  isHighlighted,
  isFree,
  isAnnual,
  subscriptionType,
  isActive,
  isDisabled,
  onSelectPricingPlan,
  isUserSubscribed,
  isEnterprise,
}: PricingCardProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const isActiveState =
    typeof isActive === "boolean" ? isActive : isActive?.(isAnnual);

  const handlePlanSelection = async () => {
    if (isActiveState) return;
    setIsLoading(true);
    try {
      await onSelectPricingPlan(
        subscriptionType,
        isAnnual ? "Annually" : "Monthly"
      );
    } finally {
      setIsLoading(false);
      setShowConfirmDialog(false);
    }
  };

  const renderPricing = () => {
    if (isFree) {
      return <span className="text-4xl font-bold">FREE</span>;
    }

    if (isEnterprise) {
      return <span className="text-4xl font-bold">Custom</span>;
    }

    if (price) {
      return (
        <div className="text-center">
          <div className="flex items-baseline">
            <span className="text-4xl font-bold">
              ${isAnnual ? price.annually : price.monthly}
            </span>
            <span className="ml-1 text-gray-500">
              /{isAnnual ? "year" : "month"}
            </span>
          </div>
          {isAnnual && (
            <p className="text-sm text-gray-500 mt-2">
              ${(price.annually / 12).toFixed(2)} per month
            </p>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={`px-6 py-8 h-full flex flex-col bg-white rounded-lg border-2 ${
        isHighlighted ? "border-blue-500 shadow-lg" : "border-gray-200"
      }`}
    >
      <h3 className="text-2xl font-bold text-gray-900">{title}</h3>

      <div className="mt-4 flex flex-col items-center">{renderPricing()}</div>

      <ul className="mt-6 space-y-4 flex-grow">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <CheckIcon className="h-5 w-5 text-green-500 flex-shrink-0" />
            <span className="ml-3 text-gray-600">{feature.text}</span>
          </li>
        ))}
      </ul>

      {!isFree && (
        <div className="mt-8">
          {isActiveState ? (
            <div className="text-center py-2 bg-gray-100 rounded-md">
              Current Plan
            </div>
          ) : isEnterprise ? (
            <div className="space-y-3">
              <ButtonLarge
                type="button"
                theme="primary"
                onClick={() =>
                  window.open(
                    "https://calendly.com/hrishi-vora/alayna-intro",
                    "_blank"
                  )
                }
                buttonText="Book a Demo"
              />
            </div>
          ) : (
            <Dialog.Root
              open={showConfirmDialog}
              onOpenChange={setShowConfirmDialog}
            >
              <Dialog.Trigger asChild>
                <ButtonLarge
                  type="button"
                  theme="primary"
                  disabled={isDisabled || isActiveState || isLoading}
                  onClick={() => {
                    if (isUserSubscribed && !isActiveState) {
                      setShowConfirmDialog(true);
                    } else {
                      handlePlanSelection();
                    }
                  }}
                  buttonText={
                    isLoading
                      ? "Processing..."
                      : isDisabled
                      ? "Not Available"
                      : isUserSubscribed && !isActiveState
                      ? "Upgrade"
                      : "Choose plan"
                  }
                />
              </Dialog.Trigger>

              <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/50 z-[10001]" />
                <Dialog.Content className="fixed top-1/2 left-1/2 transform z-[10002] -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg max-w-md w-full">
                  <h3 className="text-lg font-bold mb-4">
                    Confirm Plan Upgrade
                  </h3>
                  <p className="mb-6">
                    Are you sure you want to upgrade to the {title} -{" "}
                    {isAnnual ? "Yearly" : "Monthly"} plan?
                    <br />
                    <br />
                    Note: You will be charged a{" "}
                    <a
                      href="https://docs.stripe.com/billing/subscriptions/prorations"
                      target="_blank"
                      className="text-blue-600 underline"
                    >
                      prorated amount
                    </a>{" "}
                    based upon the remaining duration of your current plan.
                  </p>
                  <div className="flex justify-end gap-3">
                    <ButtonLarge
                      type="button"
                      theme="secondary"
                      onClick={() => setShowConfirmDialog(false)}
                      buttonText="Cancel"
                    />
                    <ButtonLarge
                      type="button"
                      theme="primary"
                      onClick={handlePlanSelection}
                      buttonText="Confirm Upgrade"
                    />
                  </div>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          )}
        </div>
      )}
    </div>
  );
};

// Main component continues in next message...
const PricingComponent = () => {
  const { auth, payments } = useStores();
  const { addToast } = useToastsContext();
  const [frequency, setFrequency] = useState("annual");
  const user = toJS(auth.user?.toGQLAttributes());

  const plans = [
    {
      id: "alayna-copilot-free",
      title: "AskAlayna Free Plan",
      isFree: true,
      features: [
        { text: "10 Chatbot uses", included: true },
        { text: "3 Differentiated Resources uses", included: true },
        { text: "3 Curriculum Creations uses", included: true },
        { text: "3 Image Creations uses", included: true },
      ],
      subscriptionType: "Free",
    },
    {
      id: "alayna-copilot-pro",
      title: "AskAlayna Pro Plan",
      price: {
        monthly: import.meta.env.VITE_APP_PRO_PLAN_MONTHLY_PRICE,
        annually: import.meta.env.VITE_APP_PRO_PLAN_ANNUAL_PRICE,
      },
      features: [
        { text: "Unlimited Chatbot uses", included: true },
        { text: "Unlimited Differentiated Resources uses", included: true },
        { text: "Unlimited Curriculum Creations uses", included: true },
        { text: "Unlimited Image Creations uses", included: true },
      ],
      subscriptionType: "Copilot",
      isActive: (isAnnual: boolean) =>
        user?.subscriptionType === "Copilot" &&
        ((isAnnual && user.subscriptionInterval === "Annually") ||
          (!isAnnual && user.subscriptionInterval === "Monthly")),
      isDisabled: user?.subscriptionType === "CopilotSlidesBundle",
    },
    {
      id: "alayna-copilot-slides-pro-bundle",
      title: "AskAlayna + Slides Bundle",
      price: {
        monthly: import.meta.env.VITE_APP_PRO_PLAN_BUNDLE_MONTHLY_PRICE,
        annually: import.meta.env.VITE_APP_PRO_PLAN_BUNDLE_ANNUAL_PRICE,
      },
      isHighlighted: true,
      features: [
        { text: "All AskAlayna Pro features", included: true },
        { text: "All Slides Generator Pro features", included: true },
        { text: "Save additional $49 with this bundle", included: true },
      ],
      subscriptionType: "CopilotSlidesBundle",
      isActive: (isAnnual: boolean) =>
        user?.subscriptionType === "CopilotSlidesBundle" &&
        ((isAnnual && user.subscriptionInterval === "Annually") ||
          (!isAnnual && user.subscriptionInterval === "Monthly")),
    },
    {
      id: "enterprise",
      title: "Schools & Districts Plan",
      isEnterprise: true,
      features: [
        { text: "Volume discounts", included: true },
        { text: "Free Professional Development", included: true },
        { text: "Dedicated support", included: true },
        { text: "Single Sign-On & LMS Integration", included: true },
      ],
      subscriptionType: "Enterprise",
    },
  ];

  const handlePlanSelection = async (
    subscriptionType: string,
    paymentInterval: string
  ) => {
    try {
      if (user?.subscriptionType) {
        // Upgrade subscription
        const { data, error } = await payments.upgradeSubscription(
          subscriptionType as EnumUserSubscriptionType,
          paymentInterval as EnumSubscriptionInterval
        );

        if (data) {
          addToast("Subscription updated successfully", { type: "success" });
          auth.refreshSubscriptionUser();
        } else if (error) {
          addToast(error, { type: "error" });
        }
      } else {
        // Create new subscription
        const { data, error } = await payments.createStripeCheckoutSession(
          subscriptionType as EnumUserSubscriptionType,
          paymentInterval as EnumSubscriptionInterval
        );

        if (error) {
          addToast(error, { type: "error" });
        } else {
          window.location.href = data;
        }
      }
    } catch (error) {
      addToast("An error occurred. Please try again.", { type: "error" });
    }
  };

  if (!user) return null;

  return (
    <div className="py-12">
      {/* Special Offer Banner */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-8">
        <div className="bg-pink-100 p-4 rounded-md text-center">
          <p className="text-pink-700 font-semibold">
            🎓 Special Offer: Get FREE access to our{" "}
            <a
              href="https://aicourse.podia.com/using-ai-as-a-teaching-assistant"
              target="_blank"
              className="underline hover:text-pink-800"
            >
              "AI for Educators 101"
            </a>{" "}
            course when you purchase any PRO plan yearly!
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Billing Cycle Selection */}
        <div className="flex justify-center mb-12">
          <div className="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              onClick={() => setFrequency("monthly")}
              className={`px-4 py-2 text-sm font-medium rounded-l-md ${
                frequency === "monthly"
                  ? "bg-[#0588f0] text-white"
                  : "bg-white text-gray-700 hover:bg-gray-50"
              } border border-gray-300`}
            >
              Monthly
            </button>
            <button
              type="button"
              onClick={() => setFrequency("annual")}
              className={`px-4 py-2 text-sm font-medium rounded-r-md ${
                frequency === "annual"
                  ? "bg-[#0588f0] text-white"
                  : "bg-white text-gray-700 hover:bg-gray-50"
              } border border-l-0 border-gray-300`}
            >
              Annual
              <span className="ml-1.5 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                Save 20%
              </span>
            </button>
          </div>
        </div>

        {/* Pricing Cards Grid */}
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-4">
          {plans.map((plan) => (
            <PricingCard
              key={plan.id}
              {...plan}
              isAnnual={frequency === "annual"}
              isUserSubscribed={!!user.subscriptionType}
              onSelectPricingPlan={handlePlanSelection}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(PricingComponent);
