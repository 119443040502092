import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";
import { formatDistanceToNow } from "date-fns";
import { observer } from "mobx-react";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
  EnumDriveDriveType,
  RecentActivityItem,
} from "../__generated__/graphql";
import Button from "../components/Button";
import ButtonLarge from "../components/ButtonLarge";
import AccountDropdown from "../components/Dropdowns/AccountDropdown";
import Pricing from "../components/Pricing";
import ReferralProgramModal from "../components/ReferralProgramModal";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TikTokIcon,
  XIcon,
} from "../components/Sidebar/AppSidebar/SocialIcons";
import { useModalContext } from "../contexts/modals";
import useStores from "../hooks/useStores";
import {
  AppsaddIcon,
  Book01Icon,
  ChatIcon,
  File03Icon,
  ImageIcon,
  MonitorIcon,
} from "../icon-imports";
import { PricingPlanTier } from "../models/User";
import {
  activeToolRoute,
  conversationRoute,
  driveRoute,
  editResourceRoute,
  imageGeneratorOutputRoute,
  instantResourcesOutputRoute,
} from "../utils/routeHelper";

const tools = [
  {
    title: "Chat with me",
    description:
      "Your AI teaching assistant for lesson planning, parent communication, IEPs, and daily tasks.",
    path: "/chat",
    color: "sky",
    logo: "/imgs/logos/chat-with-me.png",
  },
  {
    title: "Differentiate It",
    description:
      "Create leveled teaching resources instantly, customized by grade level and language proficiency.",
    path: "/instant-resources",
    color: "green",
    logo: "/imgs/logos/differentiate-it.png",
  },
  {
    title: "Design Curriculum",
    description:
      "Build standards-aligned lesson plans, assessments, and activities with AI-powered guidance.",
    path: "/tools",
    color: "red",
    logo: "/imgs/logos/design-curriculum.png",
  },
  {
    title: "Create Images",
    description:
      "Generate custom educational visuals and artwork to enhance student engagement and learning.",
    path: "/image-generator",
    color: "yellow",
    logo: "/imgs/logos/create-images.png",
  },
];

const otherTools = [
  {
    title: "Alayna AI for Google Slides",
    description: "Craft stunning presentations using our extension",
    path: "/slides",
    color: "orange",
    logo: "/imgs/logos/slides-generator-no-text.png",
    featureImg: "/imgs/other-tools/slides.png",
  },
  {
    title: "MasteryMate",
    description: "Create Project-Based Learning (PBL) experiences with AI",
    externalLink: "https://masterymate.ai",
    color: "purple",
    logo: "/imgs/logos/masterymate-no-text.png",
    featureImg: "/imgs/other-tools/masterymate.png",
  },
  {
    title: "GradingPal",
    description: "Automatically grade and provide personalized feedback",
    path: "/grading",
    color: "blue",
    logo: "/imgs/logos/gradingpal-no-text.png",
    featureImg: "/imgs/other-tools/gradingpal.png",
  },
];

const resources = [
  {
    title: "PD Deck",
    description:
      "Use this to explain Alayna AI to someone in your school/district",
    action: "View Deck",
    path: "https://www.canva.com/design/DAF5XKzIa_o/PLPm0juXs0bBBT_LQCBSSQ/edit?utm_content=DAF5XKzIa_o&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton",
    external: true,
  },
  {
    title: "Facebook Group",
    description: "Join our Community of Educators.",
    action: "View Group",
    path: "https://www.facebook.com/groups/alaynaforeducators",
    external: true,
  },
  {
    title: "Become an Expert",
    description:
      "View our AI Training Course and become an certified Alayna AI educator.",
    action: "View Course",
    path: "https://aicourse.podia.com/using-ai-as-a-teaching-assistant",
    external: true,
  },
  {
    title: "Pricing Plans",
    description:
      "Learn about our pricing plans and how to get started with Alayna AI for your school/district",
    action: "View Pricing Page",
    path: "https://alayna.ai/pricing",
    external: true,
  },
];

// Add this helper function
const getActivityUrl = (type: string, url: string) => {
  switch (type) {
    case "Chat":
      return { path: conversationRoute(url), external: false };
    case "InstantResources":
      return { path: instantResourcesOutputRoute(url), external: false };
    case "CurriculumCreator":
      return { path: activeToolRoute(url), external: false };
    case "ImageGenerator":
      return { path: imageGeneratorOutputRoute(url), external: false };
    case "Slides":
      return { path: url, external: true };
    case "Documents":
      return { path: editResourceRoute(url), external: false };
    default:
      return { path: url, external: false };
  }
};

function Dashboard() {
  const navigate = useNavigate();

  const { addModal } = useModalContext();

  const { drives } = useStores();

  const myDriveId = React.useMemo(() => {
    const myDrive = drives.sortedData.find((drive) => {
      return drive.driveType === EnumDriveDriveType.MyDrive;
    });

    if (!myDrive) return null;

    return myDrive.id;
  }, [drives.sortedData]);

  const { auth } = useStores();

  if (!auth.user) return null;

  const isPaidPlan = auth.user.isSubscribedToPlan;

  const freeTrialDaysLeft = auth.user.freeTrialDaysLeft;

  const currentPlan = auth.user.currentPlan;

  console.log("Current Plan", currentPlan);

  // Add state for recent activities
  const [recentActivities, setRecentActivities] = React.useState<
    RecentActivityItem[]
  >([]);

  // Fetch recent activities on component mount
  React.useEffect(() => {
    const fetchRecentActivities = async () => {
      const items = await auth.getRecentActivityItems();
      setRecentActivities(items);
    };

    fetchRecentActivities();
  }, [auth]);

  // Helper function to get icon and color based on activity type
  const getActivityMeta = (type: string) => {
    switch (type) {
      case "Chat":
        return { icon: ChatIcon, color: "sky" };
      case "InstantResources":
        return { icon: AppsaddIcon, color: "green" };
      case "CurriculumCreator":
        return { icon: Book01Icon, color: "red" };
      case "ImageGenerator":
        return { icon: ImageIcon, color: "yellow" };
      case "Slides":
        return { icon: MonitorIcon, color: "orange" };
      case "Documents":
        return { icon: File03Icon, color: "red" };
      default:
        return { icon: File03Icon, color: "slate" };
    }
  };

  // Helper function to get friendly name for activity type
  const getActivityTypeName = (type: string) => {
    switch (type) {
      case "Chat":
        return "Chat with me";
      case "InstantResources":
        return "Differentiate It";
      case "CurriculumCreator":
        return "Design Curriculum";
      case "ImageGenerator":
        return "Create Images";
      case "Slides":
        return "Create Slides";
      case "Documents":
        return "Document";
      default:
        return "Resource";
    }
  };

  // Add state to track current slide
  const [currentSlide, setCurrentSlide] = React.useState(0);

  // Custom arrow components
  const NextArrow = ({ onClick }: { onClick?: () => void }) => (
    <button
      onClick={onClick}
      className="absolute -right-4 top-1/2 -translate-y-1/2 z-10 p-2 rounded-full bg-white shadow-md hover:bg-slate2 transition-colors"
      aria-label="Next slides"
    >
      <ChevronRightIcon className="w-5 h-5 text-slate11" />
    </button>
  );

  const PrevArrow = ({ onClick }: { onClick?: () => void }) => {
    // Don't render if we're at the beginning
    if (currentSlide === 0) return null;

    return (
      <button
        onClick={onClick}
        className="absolute -left-4 top-1/2 -translate-y-1/2 z-10 p-2 rounded-full bg-white shadow-md hover:bg-slate2 transition-colors"
        aria-label="Previous slides"
      >
        <ChevronLeftIcon className="w-5 h-5 text-slate11" />
      </button>
    );
  };

  // Updated slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (_: number, next: number) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getActivityIconClasses = (color: string) => {
    return clsx(
      "shrink-0 w-10 h-10 rounded-xl flex items-center justify-center",
      {
        "bg-sky-50": color === "sky",
        "bg-green-50": color === "green",
        "bg-yellow-50": color === "yellow",
        "bg-red-50": color === "red",
        "bg-orange-50": color === "orange",
      }
    );
  };

  const getActivityIconColorClasses = (color: string) => {
    return clsx("w-5 h-5", {
      "text-sky-600": color === "sky",
      "text-green-600": color === "green",
      "text-yellow-600": color === "yellow",
      "text-red-600": color === "red",
      "text-orange-600": color === "orange",
    });
  };

  const getActivityTypeClasses = (color: string) => {
    return clsx("px-2 py-0.5 rounded-full", {
      "bg-sky-50 text-sky-700": color === "sky",
      "bg-green-50 text-green-700": color === "green",
      "bg-yellow-50 text-yellow-700": color === "yellow",
      "bg-red-50 text-red-700": color === "red",
      "bg-orange-50 text-orange-700": color === "orange",
    });
  };

  // Add this helper component above the return statement
  const ActivityCard = ({ activity }: { activity: RecentActivityItem }) => {
    const { icon: ActivityIcon, color } = getActivityMeta(activity.type);
    const typeName = getActivityTypeName(activity.type);
    const { path, external } = getActivityUrl(activity.type, activity.url);

    return (
      <div className="p-2">
        <a
          href={path}
          target={external ? "_blank" : undefined}
          rel={external ? "noopener noreferrer" : undefined}
          className="block bg-white rounded-2xl p-6 shadow-sm hover:shadow-sm transition-all border border-slate-200 hover:border-slate-300 h-full"
        >
          <div className="flex gap-4">
            <div className={getActivityIconClasses(color)}>
              <ActivityIcon className={getActivityIconColorClasses(color)} />
            </div>
            <div className="flex-1 min-w-0">
              <h3 className="font-medium text-slate-900 mb-1 truncate">
                {activity.title}
              </h3>
              <div className="flex items-center gap-2 text-xs">
                <span className={getActivityTypeClasses(color)}>
                  {typeName}
                </span>
                <span className="text-slate10">
                  {formatDistanceToNow(new Date(activity.updatedAt), {
                    addSuffix: true,
                  })}
                </span>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-[#fafafa] max-w-[100vw] overflow-x-hidden">
      {/* Header */}
      <div className="sticky top-0 left-0 right-0 bg-white border-b border-slate-200 z-50">
        <div className="h-18 px-6 md:px-8 flex items-center justify-between max-w-[1600px] mx-auto">
          <div className="flex items-center gap-3">
            <img
              src="/imgs/logos/askalayna.png"
              alt="Ask Alayna Logo"
              className="h-10"
            />
            {currentPlan !== PricingPlanTier.Basic && (
              <span className="px-2 py-0.5 text-xs font-medium bg-gradient-to-r from-pink-500 to-rose-500 text-white rounded-full">
                PRO
              </span>
            )}
          </div>

          <div className="flex items-center gap-4">
            {!!myDriveId && (
              <ButtonLarge
                buttonText="My Workspace"
                theme="secondary"
                type="button"
                rounded="full"
                onClick={() => navigate(driveRoute(myDriveId))}
              />
            )}
            <AccountDropdown>
              <button className="flex items-center gap-2 p-2 hover:bg-slate-100 rounded-full transition-all">
                {auth.user.avatarUrl ? (
                  <img
                    src={auth.user.avatarUrl}
                    alt="User Avatar"
                    className="w-8 h-8 rounded-full"
                  />
                ) : (
                  <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-white font-medium">
                    {auth.user?.firstName?.charAt(0)}
                    {auth.user?.lastName?.charAt(0)}
                  </div>
                )}
                <ChevronDownIcon className="w-4 h-4 text-slate-600" />
              </button>
            </AccountDropdown>
          </div>
        </div>
      </div>

      <div className="absolute top-18 left-0 right-0 w-full bg-pink-50 border-b border-pink-100">
        <div
          className={clsx(
            "flex items-center justify-center py-3 px-4",
            "text-sm text-pink-700"
          )}
        >
          <span>
            <strong className="mr-2">Introducing MasteryMate -</strong>
            {
              "Worlds First AI-powered Project-Based Learning Platform by Alayna AI."
            }
            <a
              href="https://alayna.ai/updates/introducing-masterymate"
              target="_blank"
              className="ml-1 font-semibold underline hover:text-blue-800"
            >
              {"Read more"}
            </a>
          </span>
        </div>
      </div>

      {/* Main Content */}
      <div className="pt-24 px-6 md:px-8 max-w-[1600px] mx-auto">
        {/* Recent Activities Carousel */}
        {recentActivities.length > 0 && (
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-slate-900 mb-2">
              Continue where you left off
            </h2>
            <div className="relative pb-3">
              {recentActivities.length > 3 ? (
                <Slider {...sliderSettings}>
                  {recentActivities.map((activity) => (
                    <ActivityCard key={activity.url} activity={activity} />
                  ))}
                </Slider>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {recentActivities.map((activity) => (
                    <ActivityCard key={activity.url} activity={activity} />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {/* Tools Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-12">
          <div className="lg:col-span-2">
            <h2 className="text-2xl font-bold text-slate-900 mb-6">
              Pick your AI tool
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {tools.map((tool) => (
                <Link
                  key={tool.title}
                  to={tool.path}
                  className="group block h-full"
                >
                  <div className="bg-white p-6 xl:p-8 rounded-2xl border border-slate-200 hover:border-slate-300 hover:shadow-sm transition-all h-full">
                    <div className="flex items-start gap-6 h-full">
                      <img
                        src={tool.logo}
                        alt={tool.title}
                        className="w-16 h-16 shrink-0"
                      />
                      <div className="flex flex-col min-h-0">
                        <h2 className="text-2xl font-semibold text-slate-900 mb-3">
                          {tool.title}
                        </h2>
                        <p className="text-base text-slate-600">
                          {tool.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          {/* Resources Column */}
          <div className="flex flex-col gap-4 col-span-1">
            <div>
              <h2 className="text-2xl font-bold text-slate-900 mb-6">
                Resources
              </h2>
              <div className="space-y-4">
                {resources.map((resource) => (
                  <Link
                    key={resource.title}
                    to={resource.path}
                    target={resource.external ? "_blank" : undefined}
                    rel={resource.external ? "noopener noreferrer" : undefined}
                    className="block p-4 rounded-xl bg-white border border-slate-200 hover:border-slate-300 hover:shadow-sm transition-all group"
                  >
                    <h3 className="font-semibold text-slate-900 mb-1">
                      {resource.title}
                    </h3>
                    {/* <p className="text-sm text-slate-600 mb-2">
                    {resource.description}
                  </p> */}
                    <span className="text-sm font-medium text-[#0588f0] hover:text-blue-700 flex items-center gap-1">
                      {resource.action}
                      <span className="transition-transform group-hover:animate-arrow-right">
                        →
                      </span>
                    </span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Upgrade Section */}
        {!auth.user.enterpriseUser && !isPaidPlan && (
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-slate-900 mb-6">
              Your AskAlayna Plan
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Trial Progress or Upgrade Card */}
              <div className="rounded-2xl bg-white border border-slate-200 p-8">
                {freeTrialDaysLeft > 0 ? (
                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold text-blue-900 flex items-center gap-2">
                      <span>⏳</span> Free Trial for Pro
                    </h3>
                    <p className="text-blue-800">
                      {`${freeTrialDaysLeft} days until you're downgraded to Free plan.`}
                    </p>
                  </div>
                ) : (
                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold text-blue-900 flex items-center gap-2">
                      <span>⭐</span> Upgrade to Pro
                    </h3>
                    <p className="text-blue-800">
                      Unlock the full potential of Alayna with our Pro plan.
                    </p>
                  </div>
                )}
                <Button
                  type="button"
                  theme="primary"
                  buttonText="Upgrade Now"
                  width="250"
                  onClick={() => {
                    addModal({
                      content: (
                        <div className="relative overflow-y-scroll h-full px-8">
                          <Pricing />
                        </div>
                      ),
                      size: "lg",
                    });
                  }}
                  rounded="medium"
                  className="mt-6"
                />
              </div>

              {/* Referral Card */}
              <div className="rounded-2xl bg-white border border-slate-200 p-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-pink-900 flex items-center gap-2">
                    <span>🩷</span> Refer teachers, earn free upgrades
                  </h3>
                  <p className="text-pink-800">
                    For every teacher you refer, you get a free upgrade to your
                    plan.
                  </p>
                  <Button
                    type="button"
                    theme="secondary"
                    width="250"
                    buttonText="Start Referring"
                    onClick={() => {
                      addModal({
                        content: <ReferralProgramModal />,
                        size: "md",
                      });
                    }}
                    rounded="medium"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Other Tools */}
        <div className="my-12">
          <h2 className="text-2xl font-bold text-slate-900 mb-6">
            Other Tools by Alayna AI
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {otherTools.map((tool) => (
              <div
                key={tool.title}
                className="bg-white rounded-2xl p-4 border border-slate-200 hover:border-slate-300 transition-all"
              >
                <div className="flex flex-col gap-4">
                  <div className="flex-1 flex flex-col gap-3">
                    <div className="flex items-center gap-3 mb-1.5">
                      <img
                        src={tool.logo}
                        alt={tool.title}
                        className="w-10 h-10"
                      />
                      <div>
                        <h3 className="text-xl font-semibold text-slate-900">
                          {tool.title}
                        </h3>
                        <p className="text-sm text-slate-600">
                          {tool.description}
                        </p>
                      </div>
                    </div>
                    <div>
                      <img
                        src={tool.featureImg}
                        alt={`${tool.title} Preview`}
                        className="w-full h-auto rounded-lg border border-slate-200"
                      />
                    </div>
                    <Button
                      type="button"
                      theme="secondary"
                      buttonText={
                        tool.title === "Alayna AI for Google Slides"
                          ? "Start for free"
                          : "Sign up 100% free"
                      }
                      onClick={() => {
                        if (tool.externalLink) {
                          window.open(tool.externalLink, "_blank");
                        } else if (tool.path) {
                          navigate(tool.path);
                        }
                      }}
                      width="full"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-white border-t border-slate-200 py-16">
        <div className="max-w-[1600px] mx-auto px-6 md:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Brand Column */}
            <div className="space-y-4">
              <div className="flex items-center gap-3">
                <img
                  src="/imgs/logos/askalayna.png"
                  alt="Ask Alayna Logo"
                  className="h-12"
                />
              </div>
              <p className="text-slate-600 text-sm">
                Simple, yet powerful AI teaching assistant for educators
              </p>
            </div>

            {/* Company Links */}
            <div>
              <h3 className="font-semibold text-slate-900 mb-4">Company</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="https://alayna.ai/about"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    About
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="https://alayna.ai/careers"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Careers
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="https://alayna.ai/blog"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://calendly.com/hrishi-vora/alayna-intro"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>

            {/* Resources Links */}
            <div>
              <h3 className="font-semibold text-slate-900 mb-4">Resources</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="https://alayna.ai/use-cases"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Use Cases
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://alayna.ai/pricing"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.canva.com/design/DAF5XKzIa_o/PLPm0juXs0bBBT_LQCBSSQ/edit?utm_content=DAF5XKzIa_o&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    PD Deck
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="https://alayna.ai/teacher-advisors"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Teacher Advisors
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="https://alayna.featurebase.app/"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Feedback Forum
                  </Link>
                </li>
              </ul>
            </div>

            {/* Legal Links */}
            <div>
              <h3 className="font-semibold text-slate-900 mb-4">Links</h3>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="https://alayna.ai/terms"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://alayna.ai/privacy"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://workspace.google.com/marketplace/app/alayna_ai_for_google_slides/503455951279"
                    target="_blank"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Install Slides Extension
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/data-privacy"
                    className="text-slate-600 hover:text-blue-600 transition-colors"
                  >
                    Data Privacy
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="mt-12 pt-8 border-t border-slate-200 flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-slate-600 text-sm">
              © {new Date().getFullYear()} Cues Technologies Inc. All rights
              reserved.
            </p>
            <div className="flex items-center gap-4">
              <a
                href="https://www.facebook.com/profile.php?id=61564964300303"
                target="_blank"
                rel="noopener noreferrer"
                className="text-slate-400 hover:text-blue-600 transition-colors"
                aria-label="Facebook"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://x.com/joinalayna"
                target="_blank"
                rel="noopener noreferrer"
                className="text-slate-400 hover:text-blue-600 transition-colors"
                aria-label="X (formerly Twitter)"
              >
                <XIcon />
              </a>
              <a
                href="https://www.instagram.com/joinalayna/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-slate-400 hover:text-blue-600 transition-colors"
                aria-label="Instagram"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.tiktok.com/@joinalayna"
                target="_blank"
                rel="noopener noreferrer"
                className="text-slate-400 hover:text-blue-600 transition-colors"
                aria-label="TikTok"
              >
                <TikTokIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/alayna"
                target="_blank"
                rel="noopener noreferrer"
                className="text-slate-400 hover:text-blue-600 transition-colors"
                aria-label="LinkedIn"
              >
                <LinkedinIcon />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default observer(Dashboard);
